import React from 'react';
import { withPrefix, Link } from 'gatsby';
import * as styles from './Author.module.scss';

const Author = ({ author, isIndex }) => (
  <div className={styles.author}>
    <Link to="/">
      <img
        src={withPrefix(author.photo)}
        className={styles.author__photo}
        width="80"
        height="80"
        alt={author.name}
      />
    </Link>

    { isIndex ? (
      <h1 className={styles.author__title}>
        <Link className={styles.author__titleLink} to="/">{author.name}</Link>
      </h1>
    ) : (
      <h2 className={styles.author__title}>
        <Link className={styles.author__titleLink} to="/">{author.name}</Link>
      </h2>
    )}
    <p className={styles.author__subtitle}>{author.bio}</p>
    {/*<p className={styles.author__subtitle}>*/}
    {/*<img alt="Amr Yousef" className={styles.author__locationIcon} src={withPrefix('/marker-12.png')}/>*/}
    {/*{author.location}*/}
    {/*</p>*/}
  </div>
);

export default Author;
