import React from 'react';
import * as styles from './Form.module.scss';

const Form = () => {
  return (
    <div className={styles.form}>
      <form action="/about/" name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
        <input type="hidden" name="form-name" value="contact" />
        <p className={styles.getInTouch}>Get in touch</p>
        <div style={{ display: 'flex' }}>
          <input className={styles.formInput} type="text" name="name" placeholder="Name"/>
          <div style={{ width: '10px' }}/>
          <input className={styles.formInput} type="email" name="email" placeholder="Email"/>
        </div>
        <div style={{ height: '15px' }}/>
        <div style={{ display: 'flex' }}>
          <textarea className={styles.message} rows="7"  name="message" placeholder="Message"/>
        </div>
        <div style={{ height: '15px' }}/>
        <div style={{ display: 'flex', justifyContent: "flex-end" }}>
          <button className={styles.button} type="submit">Send</button>
        </div>
      </form>
    </div>
  );
};

export default Form;
