import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';
import Page from '../components/Page';
import Form from '../components/Form/Form';

const PageTemplate = ({ data }) => {
  const {
    title: siteTitle,
    subtitle: siteSubtitle
  } = data.site.siteMetadata;

  const {
    title: pageTitle,
    description: pageDescription
  } = data.markdownRemark.frontmatter;

  const { html: pageBody } = data.markdownRemark;

  const metaDescription = pageDescription !== null ? pageDescription : siteSubtitle;

  return (
    <Layout
      LdSchema={`{
        "@context": "https://schema.org/",
        "@type": "Person",
        "name": "Amr Yousef",
        "givenName": "Amr",
        "familyName": "Yousef",
        "url": "https://amryousef.me",
        "image": "https://amryousef.me/amr.png",
        "sameAs": [
          "https://twitter.com/amrfarid140/",
          "https://www.linkedin.com/in/yousefamr/",
          "https://github.com/amrfarid140/",
          "https://amryousef.me"
        ],
        "jobTitle": "Software Engineer"
      }`}
      title={`${pageTitle} - ${siteTitle}`}
      description={metaDescription}
    >
      <Sidebar />
      <Page title={pageTitle}>
        <div dangerouslySetInnerHTML={{ __html: pageBody }} />
        <Form />
      </Page>
    </Layout>
  );
};

export const query = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        subtitle
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date
        description
      }
    }
  }
`;

export default PageTemplate;
